<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-22 09:22:51
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-07 17:31:23
-->
<template>
  <div class="information-detail">
    <div class="information-detail-body">
      <!-- 新闻详情页 -->
      <div class="information-detail-body-left">
        <div class="title text-ellipsis">
          {{ list.title }}
        </div>
        <div class="watch-amount-time">
          <div class="watch-amount">
            <span class="iconfont icon-yanjing"></span>
            <span class="watch-amount-num">
              {{ list.views || 0 }}
            </span>
          </div>
          <div class="time">{{ list.createDate }}</div>
        </div>
        <div class="text" v-html="list.detail"></div>
        
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // 营销活动详情页中间展示数据
      list: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    query_getlist() {
      this.$api.getMarketingDetail(this.$route.query.id).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.list = data;
        }
      });
    }
  },
  created() {
    this.query_getlist();
  },
  mounted() {
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
